export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25')
];

export const server_loads = [];

export const dictionary = {
		"/": [2],
		"/about": [3],
		"/auth": [4],
		"/auth/sso": [5],
		"/dash": [6],
		"/events": [7],
		"/events/create": [9],
		"/events/edit/[eventId]": [10],
		"/events/[documentId]": [8],
		"/more": [11],
		"/news": [12],
		"/news/create": [15],
		"/news/[newsId]": [13],
		"/news/[newsId]/edit": [14],
		"/policies": [16],
		"/policies/create": [18],
		"/policies/edit/[policyId]": [19],
		"/policies/[policyId]": [17],
		"/programs": [20],
		"/programs/create": [22],
		"/programs/edit/[programId]": [23],
		"/programs/[documentId]": [21],
		"/staff-portal": [24],
		"/timetable": [25]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';